import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Auth from '../containers/Auth'
import Header from './header'
import { Container as BaseContainerStyles } from '../styledComponents/layout'

import './layout.css'

const Container = styled(BaseContainerStyles)`
    padding-top: 0;
`

const TemplateWrapper = ({ children, ...props }) => (
    <Auth>
        {auth => {
            return (
                <div>
                    <StaticQuery
                        query={graphql`
                            query AboutQuery {
                                site {
                                    siteMetadata {
                                        title
                                    }
                                }
                            }
                        `}
                        render={data => (
                            <>
                                <Helmet
                                    title={data.site.siteMetadata.title}
                                    meta={[
                                        {
                                            name: 'description',
                                            content: 'Sample',
                                        },
                                        {
                                            name: 'keywords',
                                            content: 'sample, something',
                                        },
                                    ]}
                                />
                                <Header
                                    background="background-image: linear-gradient(116deg, blueviolet 0%, rebeccapurple 100%)"
                                    title={data.site.siteMetadata.title}
                                    {...auth}
                                />
                                <Container>
                                    {children({
                                        ...auth,
                                        ...props,
                                    })}
                                </Container>
                            </>
                        )}
                    />
                </div>
            )
        }}
    </Auth>
)

TemplateWrapper.propTypes = {
    children: PropTypes.func.isRequired,
}

export default TemplateWrapper
