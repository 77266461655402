import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import data from '../../stories'

const stories = data.map(story => (
    <Link key={story.slug} to={`/${story.slug}`} style={{ flex: 1, minWidth: 200, padding: 7.5 }}>
        <h2>{story.title}</h2>
        <p>{story.excerpt}</p>
    </Link>
))

const IndexPage = () => (
    <Layout>
        {auth => (
            <div style={{ display: 'flex', marginLeft: -7.5, marginRight: -7.5, flexWrap: 'wrap' }}>
                <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
                {stories}
            </div>
        )}
    </Layout>
)

export default IndexPage
